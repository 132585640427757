/**
 * External dependencies
 */
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { router } from '@inertiajs/react';
import classNames from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { isFunction } from 'lodash';

/**
 * Internal dependencies
 */
import ModalManagerContent from './modal-manager-content';

const ModalContext = createContext({});

const DELAY = 200;
const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keydown';

export const ModalProvider = ({ children }) => {
    const [modals, setModals] = useState([]);
    const lastModal = modals[modals.length - 1];
    const modalManagerProps = lastModal?.props?.modalManager;

    const onAfterCloseModal = modalManagerProps?.onAfterCloseModal;
    const isFunctionOnAfterCloseModal = isFunction(onAfterCloseModal);

    const onBeforeRouteChange = modalManagerProps?.onBeforeRouteChange;
    const isFunctionOnBeforeRouteChange = isFunction(onBeforeRouteChange);

    const openModal = (content) => {
        setModals((items) => [...items, content]);
    };

    const closeModal = () => {
        setModals((items) => items.slice(0, items.length - 1));
        isFunctionOnAfterCloseModal && onAfterCloseModal();
    };

    const closeAll = () => {
        setModals([]);
    };

    const handleEscKey = useCallback(
        (event) => {
            if (event.key === KEY_NAME_ESC) {
                if (typeof modalManagerProps?.onEscapeKeyPress === 'function') {
                    modalManagerProps.onEscapeKeyPress();
                } else {
                    closeModal();
                }
            }
        },
        [closeModal, onAfterCloseModal, isFunctionOnAfterCloseModal]
    );

    useEffect(() => {
        if (modals.length) {
            document.documentElement.classList.add('overflow-hidden');
        } else {
            document.documentElement.classList.remove('overflow-hidden');
        }
    }, [modals]);

    useEffect(() => {
        let beforeRouteChange;

        if (isFunctionOnBeforeRouteChange) {
            beforeRouteChange = router.on('before', (event) => {
                // control whether to proceed with navigation event externally:
                // by returning TRUE|FALSE
                return onBeforeRouteChange(event);
            });
        }

        const onRouterNavigate = router.on('navigate', () => closeAll());

        return () => {
            onRouterNavigate();
            isFunctionOnBeforeRouteChange && beforeRouteChange();
        };
    }, [onBeforeRouteChange, isFunctionOnBeforeRouteChange]);

    useEffect(() => {
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

        return () => {
            document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
        };
    }, [handleEscKey]);

    const contextValue = {
        modals,
        openModal,
        closeModal,
        closeAll,
    };

    return <ModalContext.Provider value={contextValue}>{children}</ModalContext.Provider>;
};

export const ModalManager = ({ isGlobalManager }) => {
    const { modals } = useModal();

    if (!modals) {
        return;
    }

    const lastModal = modals[modals.length - 1];
    const modalManagerProps = lastModal?.props?.modalManager || lastModal?.props?.modalmanager;
    const lastClassName = classNames('modal', modalManagerProps?.className);

    const isGlobalModal = modalManagerProps?.renderGlobal;

    const shouldRender = isGlobalManager ? isGlobalModal : !isGlobalModal;

    return (
        <TransitionGroup>
            {lastModal && shouldRender && (
                <CSSTransition
                    timeout={DELAY}
                    in={lastModal !== undefined}
                    key={lastModal.type.name || modalManagerProps?.key || modals.length}
                    classNames={{
                        appear: 'is-open',
                        appearActive: 'is-open',
                        appearDone: 'is-open',
                        enter: 'is-open',
                        enterActive: 'is-open',
                        enterDone: 'is-open',
                    }}
                    unmountOnExit
                >
                    <ModalManagerContent
                        lastClassName={lastClassName}
                        modalManagerProps={modalManagerProps}
                        lastModal={lastModal}
                    />
                </CSSTransition>
            )}
        </TransitionGroup>
    );
};

export const useModal = () => {
    return useContext(ModalContext);
};

export default useModal;
