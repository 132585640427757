/**
 * External dependencies
 */
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import useModal from './modal-manager';
import { useHeader } from '@/hosts-marketplace/blocks/header/use-header';

const ModalManagerContent = ({ lastClassName, modalManagerProps, lastModal }) => {
    const { closeModal } = useModal();
    // const { isFolded } = useHeader();

    const handleOverlayClick = () => {
        if (typeof modalManagerProps?.onOverlayClick === 'function') {
            modalManagerProps.onOverlayClick();
        } else {
            closeModal();
        }
    };

    return (
        <div
            className={classNames('sticky-holder', {
                [modalManagerProps?.globalClassName]: modalManagerProps?.globalClassName,
            })}
        >
            <div
                // className={classNames('sticky-holder__inner', {
                //     'header-folded': isFolded,
                // })}
                className="sticky-holder__inner"
            >
                <div className={lastClassName} style={modalManagerProps?.style || {}}>
                    <div className="modal__dialog">
                        <div className="modal__content">
                            {modalManagerProps?.header && (
                                <div className="modal__header">{modalManagerProps.header}</div>
                            )}
                            <div className="modal__body">{lastModal}</div>
                        </div>
                    </div>
                </div>

                {!modalManagerProps?.isOverlayOutside && (
                    <div className="sticky-holder__overlay" onClick={handleOverlayClick} />
                )}
            </div>

            {modalManagerProps?.isOverlayOutside && (
                <div className="sticky-holder__overlay" onClick={handleOverlayClick} />
            )}
        </div>
    );
};

export default ModalManagerContent;
